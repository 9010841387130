import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { Form, Formik } from 'formik';
import FormItem from '../../../../../../components/FormItem';
import Modal from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';

import { useAuthContext } from '../../../../../../contexts/AuthContext';

import { formSchema, useForm } from './useForm';

function CancelAccountModal({ isOpen, onClose }) {
    const { t } = useTranslation();
    const [{ user }] = useAuthContext();
    const { loading, handleSubmit } = useForm();

    return (
        <Modal open={isOpen} onClose={onClose} title={t('Confirmation of account cancellation')}>
            <div className="mb-3">Describe and password field</div>
            <Formik
                initialValues={{
                    password: '',
                }}
                validationSchema={formSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form autoComplete="off">
                        <FormItem
                            label={t('Your Password')}
                            name="password"
                            type="password"
                            autocomplete="off"
                            disabled={loading}
                            validationSchema={formSchema}
                            hint="For security reason you have to enter your current password to confirm that it's you"
                        />
                        <div className="flex justify-end pt-4 mt-4 border-t border-t-slate-300">
                            <Button color="primary" outline onClick={onClose}>
                                {t('Cancel')}
                            </Button>
                            <Button type="submit" color="danger" className="ml-4" loading={loading}>
                                {t('Confirm')}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

CancelAccountModal.propTypes = {};

CancelAccountModal.defaultProps = {};

export default CancelAccountModal;
