import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { Form, Formik } from 'formik';

import { useAuthContext } from '../../../../contexts/AuthContext';
import FormItem from '../../../../components/FormItem';
import Button from '../../../../components/Button';
import ButtonsWrapper from '../../../../components/ButtonsWrapper';
import Grid from '../../../../components/Grid';
import Header from '../../../../components/Header';
import { countries } from '../../../../utils/dictionaries';

import CancelAccountModal from './components/CancelAccountModal';
import { formSchema, useForm } from './useForm';

const initValues = {
    currency: [],
    countries: [],
};

function ApplicationForm({ details }) {
    const { t } = useTranslation();
    const [isCancelModalOpen, setCancelModal] = useState(false);

    const [{ user }] = useAuthContext();
    const { package: { name: packageName = '' } = {} } = user;
    const { loading, handleSubmit } = useForm({ details });

    const data = JSON.parse(get(details, 'data', '{}'));
    return (
        <React.Fragment>
            <Formik
                initialValues={{ ...initValues, ...data }}
                validationSchema={formSchema}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <Grid.Container fluid>
                            <Grid.Row>
                                <Grid.Col>
                                    <Header as="h3" dividing>
                                        {t('Basic')}
                                    </Header>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                {/* <Grid.Col md={6} lg={3}>
                                <FormItem
                                    name="currency"
                                    label="Currency"
                                    type="dropdown"
                                    options={currencies}
                                    disabled={loading}
                                    validationSchema={formSchema}
                                    isMultiple
                                    hint="Leave this field empty to use all options"
                                />
                            </Grid.Col> */}
                                <Grid.Col md={6} lg={3}>
                                    <FormItem
                                        name="countries"
                                        label="Countries"
                                        type="dropdown"
                                        options={countries}
                                        disabled={loading}
                                        validationSchema={formSchema}
                                        isMultiple
                                        hint="Leave this field empty to use all options"
                                    />
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col>
                                    <ButtonsWrapper>
                                        <Button type="submit" color="primary" loading={loading}>
                                            {t('Save')}
                                        </Button>
                                    </ButtonsWrapper>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Container>
                    </Form>
                )}
            </Formik>
            <Grid.Container fluid>
                <Grid.Row>
                    <Grid.Col>
                        <Header as="h3" dividing>
                            {t('Package')}
                        </Header>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col md={9} className="flex items-center">
                        {packageName}
                    </Grid.Col>
                    <Grid.Col md={3} className="flex justify-end">
                        <Button color="danger" outline onClick={() => setCancelModal(true)}>
                            {t('Cancel subscription')}
                        </Button>
                        <CancelAccountModal isOpen={isCancelModalOpen} onClose={() => setCancelModal(false)} />
                    </Grid.Col>
                </Grid.Row>
            </Grid.Container>
        </React.Fragment>
    );
}

ApplicationForm.propTypes = {
    details: PropTypes.object,
};

ApplicationForm.defaultProps = {
    details: {},
};

export default ApplicationForm;
