import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { HiOutlineLogout } from 'react-icons/hi';
import get from 'lodash/get';
import { useNotification } from '../../contexts/NotificationContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useConfigContext } from '../../contexts/ConfigContext';
import urls from '../../setup/urls';
import { getNavItems, getUserItems } from '../../setup';
import AdminLayout from './AdminLayout';

function withContext(Component) {
    function WrappedField(props) {
        const [mobileOpen, setMobileOpen] = useState(false);
        const { t } = useTranslation();
        const location = useLocation();
        const navigate = useNavigate();

        const [, { addWarning }] = useNotification();
        const [{ loading, pageTitle }] = useConfigContext();
        const [{ user, isLogged, loading: userIsLoading }, { logout }] = useAuthContext();

        useEffect(() => {
            setMobileOpen(false);
        }, [location]);

        useEffect(() => {
            if (!isLogged && !userIsLoading) {
                navigate(get(urls, 'login.route'));
            }
        }, [isLogged, userIsLoading]);

        useEffect(() => {
            if (user) {
                const { settings = {}, companyStatus } = user;
                const { company = {} } = settings;
                const { name = '', address = '', nip = '', email = '' } = company;

                // company is removed
                if (companyStatus === 'archived') {
                    navigate(get(urls, 'companyArchived.route'));
                }
                // company is blocked by owner of app
                if (companyStatus === 'blocked') {
                    navigate(get(urls, 'companyBlocked.route'));
                }
                // company is inactive by user, - stop to pay and use
                if (companyStatus === 'inActive') {
                    navigate(get(urls, 'companyInactive.route'));
                }

                if (location.pathname !== get(urls, 'settings.route') && (!name || !address || !nip || !email)) {
                    addWarning(t('Some data necessary for the application works requires completion'));
                    navigate(get(urls, 'settings.route'));
                }
            }
        }, [location, user]);

        const toggleAside = () => setMobileOpen((state) => !state);

        const handleLogout = async () => {
            await logout();
        };

        const userRole = get(user, 'role', '');
        const logo = get(user, 'settings.company.logo');
        const navItems = getNavItems({ t }).filter(({ permissions = [] }) => {
            if (!permissions.length) return true;
            return permissions.includes(userRole);
        });

        const userItems = getUserItems({ t }).filter(({ permissions = [] }) => {
            if (!permissions.length) return true;
            return permissions.includes(userRole);
        });

        return (
            <Fragment>
                <Helmet>
                    <title>
                        {process.env.REACT_APP_TITLE} - {pageTitle}
                    </title>
                </Helmet>
                <Component
                    {...props}
                    loading={loading || userIsLoading}
                    pageTitle={pageTitle}
                    asideIsOpen={mobileOpen}
                    toggleAside={toggleAside}
                    userName={get(user, 'name', '')}
                    userRole={userRole}
                    navItems={navItems}
                    userItems={[
                        ...userItems,
                        {
                            type: 'button',
                            label: t('Logout'),
                            onClick: handleLogout,
                            icon: HiOutlineLogout,
                            order: 1000,
                        },
                    ]}
                    logoProps={{
                        src: logo,
                        alt: get(user, 'settings.company.name'),
                    }}
                />
            </Fragment>
        );
    }

    return WrappedField;
}

export default withContext(AdminLayout);
