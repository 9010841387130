import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { HiOutlineBan } from 'react-icons/hi';
import { useAuthContext } from '../../contexts/AuthContext';
import Grid from '../../components/Grid';
import Section from '../../components/Section';
import urls from '../../setup/urls';

function BlockedPage() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [{ user }] = useAuthContext();
    const { companyStatus } = user;

    useEffect(() => {
        if (companyStatus && companyStatus !== 'blocked') {
            navigate(get(urls, 'reservations.route'));
        }
    }, [companyStatus]);

    return (
        <Grid.Container fluid>
            <Grid.Row className="justify-center">
                <Grid.Col md={8} lg={6}>
                    <Section title={t('Company account is blocked')}>
                        <div className="flex items-center justify-center text-red-600 text-8xl">
                            <HiOutlineBan />
                        </div>
                        <div className="text-sm font-medium">
                            {t('The company account has been locked. To unlock it, please contact the helpdesk.')}
                        </div>
                    </Section>
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}
export default BlockedPage;
