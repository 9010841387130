import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { useNotification } from '../../contexts/NotificationContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { setCompanyActive } from '../../api';
import Grid from '../../components/Grid';
import Section from '../../components/Section';
import Button from '../../components/Button';
import { sendRequest } from '../../utils/request';
import urls from '../../setup/urls';

function InactivePage() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [, { addSuccess, addError }] = useNotification();
    const [{ user, iAmAdmin }] = useAuthContext();
    const { companyStatus } = user;

    useEffect(() => {
        if (companyStatus && companyStatus !== 'inActive') {
            navigate(get(urls, 'reservations.route'));
        }
    }, [companyStatus]);

    const handleActive = async () => {
        setLoading(true);
        const response = await sendRequest(setCompanyActive(), {
            method: 'PUT',
        });

        setLoading(false);
        if (response.status === 200) {
            addSuccess(t('Company account was activated'));
            window.location.reload();
        } else {
            addError(t(response.data.error));
        }
    };

    return (
        <Grid.Container fluid>
            <Grid.Row className="justify-center">
                <Grid.Col md={8} lg={6}>
                    <Section title={t('Company account is inactive')} loading={loading}>
                        <div className="text-sm font-medium">
                            {t(
                                'The company account is inactive. During this time, subscription fees are not being charged.',
                            )}
                        </div>
                        {!iAmAdmin && (
                            <div className="flex items-center justify-center w-100">
                                <Button loading={loading} onClick={handleActive}>
                                    {t('Activate')}
                                </Button>
                            </div>
                        )}
                    </Section>
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}
export default InactivePage;
