/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, find } from 'lodash';
import { HiOutlinePencil, HiOutlineRefresh, HiPlus } from 'react-icons/hi';
import { useConfigContext } from '../../contexts/ConfigContext';
import { useNotification } from '../../contexts/NotificationContext';
import { ADMIN_ROLE, AUTH_ROLES } from '../../contexts/AuthContext/useAuth';
import { userApi } from '../../api';
import Grid from '../../components/Grid';
import Table from '../../components/Table';
import Badge from '../../components/Badge';
import EmailCell from '../../components/Table/components/EmailCell';
import RolesTooltip from '../../components/RolesTooltip';
import UserStatusTooltip from '../../components/UserStatusTooltip';
import DeleteMessage from '../../components/Table/components/DeleteMessage';
import withStatusManager, { ACTIVE_LABEL, INACTIVE_LABEL } from '../../components/Table/components/withStatusManager';
import urls from '../../setup/urls';
import { sendRequest } from '../../utils/request';

/* eslint-disable react/prop-types */
function LinkCell({ value, data: { role = '', id } = {} }) {
    return (
        <div className="rgt-cell-inner">
            {role !== ADMIN_ROLE ? <Link to={`${get(urls, 'userForm.route')}/${id}`}>{value}</Link> : value}
        </div>
    );
}

/* eslint-disable react/prop-types */
function RoleCell({ value }) {
    const { t } = useTranslation();
    const roleLabel = get(find(AUTH_ROLES, ['value', value]), 'label', value);

    return <div className="rgt-cell-inner">{t(roleLabel)}</div>;
}

function DeleteMessageComponent({ item }) {
    const { t } = useTranslation();
    const { name = '' } = item;

    return (
        <DeleteMessage
            message={t('Are you sure you want to delete this user?')}
            descriptionItems={[
                {
                    label: t('Name'),
                    value: name,
                },
            ]}
        />
    );
}

function StatusCell({ data: { isActivated = false, isActive = false } = {} }) {
    const { t } = useTranslation();
    const color = !isActivated ? 'warning' : isActive ? 'success' : 'danger';
    const label = !isActivated ? 'Not activated' : isActive ? ACTIVE_LABEL : INACTIVE_LABEL;
    return <Badge color={color}>{t(label)}</Badge>;
}

/* eslint-enable react/prop-types */

const TableComponent = withStatusManager(Table, {
    getCustomizedCol: (data) => ({
        ...data,
        label: (
            <div className="flex items-center justify-start gap-2 flex-nowrap">
                Status
                <UserStatusTooltip />
            </div>
        ),
        cellRenderer: StatusCell,
    }),
    getCustomizedActivateAction: (data) => ({
        ...data,
        isHidden: ({ data }) =>
            !get(data, 'isActivated', false) || get(data, 'isActive', false) || get(data, 'role') === ADMIN_ROLE,
    }),
    getCustomizedDeactivateAction: (data) => ({
        ...data,
        isHidden: ({ data }) =>
            !get(data, 'isActivated', false) || !get(data, 'isActive', false) || get(data, 'role') === ADMIN_ROLE,
    }),
});

function Users() {
    const { t } = useTranslation();
    const [, { setPageTitle, setLoading }] = useConfigContext();
    const [, { addSuccess, addError }] = useNotification();

    useEffect(() => {
        setPageTitle(t('Users'));
    }, []);

    const handleResendActivationEmail = async (props) => {
        const { data: { id } = {} } = props;
        try {
            setLoading(true);
            const response = await sendRequest(userApi.reactivate({ id }), {
                method: 'PUT',
            });

            setLoading(false);
            if (response.status === 200) {
                addSuccess(t('The activation email has been sent'));
            } else {
                throw new Error(t(response.data.error));
            }
        } catch (e) {
            addError(e.message);
        }
    };

    const handleResendTemporaryPasswordEmail = async (props) => {
        const { data: { id } = {} } = props;
        try {
            setLoading(true);
            const response = await sendRequest(userApi.setTemporaryPassword({ id }), {
                method: 'PUT',
            });

            setLoading(false);
            if (response.status === 200) {
                addSuccess(t('Temporary password has been set, email with password has been sent'));
            } else {
                throw new Error(t(response.data.error));
            }
        } catch (e) {
            addError(e.message);
        }
    };

    const tableConfig = {
        id: 'users',
        columns: React.useMemo(
            () => [
                {
                    field: 'name',
                    label: t('Name'),
                    pinned: true,
                    type: 'string',
                    cellRenderer: LinkCell,
                },
                {
                    field: 'email',
                    label: t('Email'),
                    type: 'string',
                    cellRenderer: EmailCell,
                    width: '360px',
                },
                {
                    field: 'role',
                    label: (
                        <div className="flex items-center justify-start gap-2 flex-nowrap">
                            {t('Role')}
                            <RolesTooltip />
                        </div>
                    ),
                    type: 'dropdown',
                    options: AUTH_ROLES,
                    cellRenderer: RoleCell,
                },
            ],
            [],
        ),
        headerActions: [
            {
                label: 'Add User',
                icon: HiPlus,
                to: `${get(urls, 'userForm.route')}/create`,
                useSeparately: true,
            },
        ],
        rowActions: [
            {
                label: 'Edit',
                icon: HiOutlinePencil,
                to: ({ data: { id } = {} }) => `${get(urls, 'userForm.route')}/${id}`,
                isHidden: ({ data }) => get(data, 'role') === ADMIN_ROLE,
            },
            {
                label: 'Resend activation email',
                icon: HiOutlineRefresh,
                type: 'button',
                onClick: handleResendActivationEmail,
                isHidden: ({ data }) => {
                    const { role, isActivated } = data;
                    return role === ADMIN_ROLE || isActivated;
                },
            },
            {
                label: 'Set temporary password',
                icon: HiOutlineRefresh,
                type: 'button',
                onClick: handleResendTemporaryPasswordEmail,
                isHidden: ({ data }) => {
                    const { role } = data;
                    return role === ADMIN_ROLE;
                },
            },
        ],
        initConfig: {
            sort: { field: 'name', direct: 'ASC' },
        },
        contextApi: userApi,
        deleteMessage: DeleteMessageComponent,
        withDelete: ({ data }) => get(data, 'role') !== ADMIN_ROLE,
        // withExport: true,
    };

    return (
        <Grid.Container fluid>
            <Grid.Row>
                <Grid.Col>
                    <TableComponent {...tableConfig} />
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}

export default Users;
