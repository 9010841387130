import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';

const STATUSES = [
    {
        label: 'Not activated',
        description:
            'The account was created, but the user did not activate it through the activation link sent to the email.',
    },
    {
        label: 'Active',
        description: 'Active account. The user can use all options of the application.',
    },
    {
        label: 'Inactive',
        description: 'Account blocked. A user with this status cannot log into the application.',
    },
];

function UserStatusTooltip() {
    const { t } = useTranslation();
    return (
        <Tooltip>
            <div className="max-w-[80vw] md:max-w-xs max-h-[50vh] md:max-h-96 overflow-y-auto">
                {STATUSES.map(({ label, description }, index) => (
                    <div key={label}>
                        <p className="mb-1 uppercase">
                            <strong>{t(label)}</strong>
                        </p>
                        <p className="text-xs leading-5">{t(description)}</p>
                        {index < STATUSES.length - 1 && <hr className="my-3" />}
                    </div>
                ))}
            </div>
        </Tooltip>
    );
}

UserStatusTooltip.propTypes = {};
UserStatusTooltip.defaultProps = {};

export default UserStatusTooltip;
