import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import Yup from '../../../../utils/yup';
import { useNotification } from '../../../../contexts/NotificationContext';
import { sendRequest } from '../../../../utils/request';
import { createClient, updateClient } from '../../../../api';
import urls from '../../../../setup/urls';

export const minFormSchema = Yup.object().shape({
    lastName: Yup.string().required().max(255),
    firstName: Yup.string().max(255),
    birthDate: Yup.date(),
});

export const formSchema = Yup.object().shape({
    lastName: Yup.string().required().max(255),
    firstName: Yup.string().max(255),
    birthDate: Yup.date(),
    email: Yup.string().email().max(255).required(),
    email2: Yup.string().email().max(255),
    phone: Yup.string().max(255),
    phone2: Yup.string().max(255),
    street: Yup.string().max(255),
    postcode: Yup.string().max(10),
    city: Yup.string().max(255),
    country: Yup.string().max(255),
    passport: Yup.string().max(255),
    passportDate: Yup.date(),
    nationality: Yup.string().max(255),
    innerNotes: Yup.string(),
});

export const useForm = ({ id }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [, { addError, addSuccess }] = useNotification();

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        const api = id ? updateClient({ id }) : createClient();

        const response = await sendRequest(api, {
            method: id ? 'PUT' : 'POST',
            data: values,
        });

        actions.setSubmitting(false);
        setLoading(false);
        if (response.status === 200) {
            addSuccess(t('Client was saved.'));
            navigate(get(urls, 'clients.route'));
        } else {
            addError(t(response.data.error));
        }
    };

    return {
        loading,
        handleSubmit,
    };
};
